

.stv {
    width: 150px;
    /* height: 180px; */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
}

/* .ribbon {
    
    overflow: hidden;
    white-space: nowrap;
    top left corner
    position: absolute;
    left: -5px;
    top: 15px;
    for 45 deg rotation
    -webkit-transform: rotate(-45deg);
       -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
         -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
    for creating shadow
    -webkit-box-shadow: 0 0 10px #888;
       -moz-box-shadow: 0 0 10px #888;
            box-shadow: 0 0 10px #888;
  } */
  /* .ribbon.success{
    background-color: #1cbb8c;
  }
  .ribbon.warning{
    background-color: #ff1741;
  } */
  /* .ribbon span {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 100% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 2px 10px;
    text-align: center;
    text-decoration: none;
    for creating shadow
    text-shadow: 0 0 5px #444;
  } */

.ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -5px;
    left: 0px;
}
.ribbon-wrapper .ribbons {
    font: bold 15px sans-serif;
    color: #333;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 7px 0;
    top: 15px;
    left: -30px;
    width: 120px;
    color: #fff;
}

.ribbon-wrapper .ribbons.success {  
    background-color: #1cbb8c;
}
.ribbon-wrapper .ribbons.warning {  
    background-color: #ff1741;
}

